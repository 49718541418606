import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import HeroImage from '../components/contentful/hero-image'
import {getHeaderSize, handleInnerNavigation} from '../helpers'
import PawsLeft from '../../static/img/landing-pages/gifs/Paws-Left-New.gif'
import PawsRight from '../../static/img/landing-pages/gifs/Paws-Right-New.gif'
import EmojiCelebrate from '../../static/img/landing-pages/icons/Emoji-Celebrate.svg';
import EmojiRocket from '../../static/img/landing-pages/icons/Emoji-Rocket.svg';
import EmojiStar from '../../static/img/landing-pages/icons/Emoji-Star.svg';
import AgentsIcon from '../../static/img/landing-pages/icons/AgentsIcon.svg';
import SatisfactionIcon from '../../static/img/landing-pages/icons/SatisfactionIcon.svg';
import StoresIcon from '../../static/img/landing-pages/icons/StoresIcon.svg';
import styles from '../scss/page.scss'
import Carousel from "react-bootstrap/Carousel";
import loadable from '@loadable/component'
const Odometer = loadable(() => (import('react-odometerjs')));
import 'odometer/themes/odometer-theme-default.css'

class RetailersPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Become a Retailer | Honest Paws®'
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'Join the many retailers that have experienced an increase in sales and return customers as an Honest Paws retailer'
      },
      children: []
    }
    this.state = {
      agentsNumber: 5,
      headerHeight: 0,
      satisfactionNumber: 90,
      show_odometer: false,
      storesNumber: 1600
    }

    this.bannerRetailersImageDesktop = this.props.data.bannerRetailersImageDesktop.childImageSharp.fluid
    this.bannerRetailersImageMobile = this.props.data.bannerRetailersImageMobile.childImageSharp.fluid
    this.featuredInLogosMobile = this.props.data.featuredInLogosMobile.childImageSharp.fluid
    this.featuredInLogosDesktop = this.props.data.featuredInLogosDesktop.childImageSharp.fluid
    this.HowItWorks1Desktop = this.props.data.HowItWorks1Desktop.childImageSharp.fixed
    this.HowItWorks2Desktop = this.props.data.HowItWorks2Desktop.childImageSharp.fixed
    this.HowItWorks3Desktop = this.props.data.HowItWorks3Desktop.childImageSharp.fixed
    this.HowItWorks1Mobile = this.props.data.HowItWorks1Mobile.childImageSharp.fixed
    this.HowItWorks2Mobile = this.props.data.HowItWorks2Mobile.childImageSharp.fixed
    this.HowItWorks3Mobile = this.props.data.HowItWorks3Mobile.childImageSharp.fixed

    this.testimonials = [
      {
        body: "Collaborating with HonestPaws has proven to be an ideal partnership for Woods Humane Society. We have witnessed the effectiveness of their superior products with our scared and anxious dogs in our shelter and believe in their value completely. The team has been incredibly helpful, responsive, flexible and is always thinking creatively about how they can best help our organization.",
        author: "Woods Humane Society"
      },
      {
        body: "We have tried dozens of products in our shop since we opened and we are a holistic Spaw so 99% of everything we use is all-natural. Once we find a product that works as well as it claims to, we reach out and try to buy these products in bulk at wholesale so that we can offer for sale the same products we use and we can highly recommend these products.",
        author: "Furbaby Grooming"
      },
      {
        body: "I have an 8-year-old English Bulldog that suffers from severe IBD, has severe mobility issues (2 torn ACL) and he is allergic to everything. He was at the vet (would come to work with me) on a weekly basis and almost died. He was on 6+ pills a day (he had his own pillbox) to try and control all of his ailments. I am very pleased to say that he has been off all of his medications for 6 months and is only using honest paws.",
        author: "Falls Road Veterinary Clinic"
      },
      {
        body: "We work with you because Benn has been awesome. From the beginning he has been there every step of the way, educating us on how to educate our customers. We use the product on our own pets and have been steadily converting more and more of our customer base to your products.",
        author: "Collar Pet Boutique and Grooming"
      }
    ]

    this.handleScroll = this.handleScroll.bind(this)
    this.initializeHubspotForm = this.initializeHubspotForm.bind(this)
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.sectionRef = document.querySelector('#numbers-info')

    this.initializeHubspotForm();

    this.setState({
      headerHeight: getHeaderSize().height,
      show_odometer: true
    })
  }

  /**
   * Hook fired when component is going to unmount
   * @return {void}
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * Handles scroll event
   * @return {void}
   */
  handleScroll() {
    if (window.scrollY > this.sectionRef.getBoundingClientRect().top) {
      this.setState({
        agentsNumber: 10,
        satisfactionNumber: 100,
        storesNumber: 1800
      })
    }
  }
  
  /**
   * Initialized HubSpot Form
   * @return {[type]}
   */
  initializeHubspotForm() {
    if ('hbspt' in window) {
      window.hbspt.forms.create({
          portalId: "6859268",
          formId: "e2f65a7d-6061-4246-b7b7-d341b26ed118",
          target: "#form-container"
      });
    } else {
      setTimeout(this.initializeHubspotForm, 500)
    }
  }
  
  render() {
    return (
      <Layout containerClassName={`page page-retailers`} location={this.props.location} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo }>
          <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
          { this.seo.children ? this.seo.children.map(el => el) : '' }
        </SEO>
        <div className="page-header-top-placeholder"></div>

        <section className="bg-white become-a-retailer">
          <div className="container">
            <div className="row">
              <div className="col-12 d-md-none p-0">
                <Img fluid={this.bannerRetailersImageMobile} alt="Retailers Program" />
              </div>
              <div className="col-10 col-lg-6 col-xl-5 mx-auto text-left">
                <h1 className="text-primary mb-0 mt-5">Become a Retailer</h1>
                <div className="description-text mt-2">Here’s your chance to become an authorized retailer with Honest Paws, giving you the ability to
                  sell the most-trusted brand in Pet CBD, and other exciting One.Pet brands!</div>
                <div className="d-flex p-0 col-9 col-lg-6 col-xl-4 mx-auto mx-md-0">
                  <a href="#sign-up-container" className="btn btn-success my-4 flex-fill">Sign Up</a>
                  {/* <a href="#sign-up-container" onClick={(e) => handleInnerNavigation(e, -this.state.headerHeight)} className="btn btn-success my-4 flex-fill">Sign Up</a> */}
                </div>
              </div>
              <div className="col-lg-6 col-xl-5 mx-auto d-none d-md-block">
                <Img fluid={this.bannerRetailersImageDesktop} alt="Retailers Program" />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white how-it-works">
          <div className="container">
            <div className="row">
              <div className="text-center col-9 mx-auto mb-5">
                <h2 className="text-primary text-center mb-0">How it Works</h2>
                <div className="description-text mt-2">We’re here to help you make it fast and simple</div>
              </div>

              <div className="col-12 col-xl-4 text-center mb-5 mb-xl-0">
                <div className="steps mb-3 d-flex justify-content-center align-items-center">
                  <div className="mr-3 d-flex justify-content-center align-items-center">1</div> <span className="text-left">Sign up by filling <br/> our short form</span>
                </div>
                <div className="">
                  <Img fixed={this.HowItWorks1Desktop} alt="How it Works Retailer Program" className="d-none d-xl-block mx-auto"/>
                  <Img fixed={this.HowItWorks1Mobile} alt="How it Works Retailer Program" className="d-xl-none"/>
                  <img src={PawsLeft} alt="Honest Paws Retailer Program" className="d-none d-xl-block gif-paws-left"/>
                </div>
              </div>

              <div className="col-12 col-xl-4 text-center mb-5 mb-xl-0 mt-xl-8">
                <div className="steps mb-3 d-flex justify-content-center align-items-center">
                  <div className="mr-3 d-flex justify-content-center align-items-center">2</div> <span className="text-left">Get qualified & <br/> talk to an agent</span>
                </div>
                <div className="">
                  <Img fixed={this.HowItWorks2Desktop} alt="How it Works Retailer Program" className="d-none d-xl-block mx-auto"/>
                  <Img fixed={this.HowItWorks2Mobile} alt="How it Works Retailer Program" className="d-xl-none" objectFit='contain'/>
                  <img src={PawsRight} alt="Honest Paws Retailer Program" className="d-none d-xl-block gif-paws-right"/>
                </div>
              </div>

              <div className="col-12 col-xl-4 text-center">
                <div className="steps mb-3 d-flex justify-content-center align-items-center">
                  <div className="mr-3 d-flex justify-content-center align-items-center">3</div> <span className="text-left">Carry Honest Paws <br/> in your retail store</span>
                </div>
                <div className="d-flex justify-content-center">
                  <Img fixed={this.HowItWorks3Desktop} alt="How it Works Retailer Program" className="d-none d-xl-block"/>
                  <Img fixed={this.HowItWorks3Mobile} alt="How it Works Retailer Program" className="d-xl-none"/>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="retailers-info">
          <div className="container" id="numbers-info">
            <div className="row text-center text-primary">
              <div className="col-12 col-md-4 mb-7 mb-md-0">
                <img src={StoresIcon} className="mb-5" alt="Stores"/>
                <div className="numbers-info">
                  +
                  {this.state.show_odometer &&
                    <Odometer format="d" value={this.state.storesNumber}/>
                  }
                </div>
                <div className="text-information">Satisfied Stores <br/>Nationwide</div>
              </div>
              <div className="col-12 col-md-4 mb-7 mb-md-0">
                <img src={AgentsIcon} className="mb-5" alt="Agents"/>
                <div className="numbers-info">
                  {this.state.show_odometer &&
                    <Odometer format="d" value={this.state.agentsNumber} animation="count"/>
                  }
                  </div>
                <div className="text-information">Agents Available With <br/>Great Customer Service</div>
              </div>
              <div className="col-12 col-md-4 mb-4 mb-md-0">
                <img src={SatisfactionIcon} className="mb-5" alt="Satisfaction"/>
                <div className="numbers-info">
                  {this.state.show_odometer &&
                    <Odometer format="d" value={this.state.satisfactionNumber}/>
                  }
                  %
                </div>
                <div className="text-information">Satisfaction Guarantee <br/>With The Highest Standards</div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white why-choose-hp pb-0 pb-md-7">
          <div className="container">
            <div className="row">
              <div className="text-center col-10 mx-auto mb-5 mb-md-6">
                <h2 className="text-primary text-center">Why Choose <br className="d-md-none"/>Honest Paws?</h2>
              </div>
              <div className="col-11 col-md-6 mx-auto mb-5 mb-md-0">
                <div className="text-primary title-description mb-3">Honest Paws offers premium CBD products for your pets.</div>
                <div className="text-gray-brown subtitle-description mb-4">We offer a wide variety of products from
                  <br/>CBD oil to CBD treats to CBD-infused peanut butter. </div>
                <ul className="text-black list-description">
                  <li className="d-flex mb-1"><img src={EmojiCelebrate} className="mr-2" alt="Celebration Icon" /><p className="d-flex align-items-center">Dog Calming Product of the Year</p></li>
                  <li className="d-flex mb-1"><img src={EmojiRocket} className="mr-2" alt="Rocket Icon"/><p className="d-flex align-items-center">2019 “Company to Watch”</p></li>
                  <li className="d-flex"><img src={EmojiStar} className="mr-2" alt="Star Icon"/><p className="d-flex align-items-center">2020 BBB Gold Star Certificate</p></li>
                </ul>
              </div>
              <div className="col-12 col-md-6 p-0">
                <iframe
                  title="Youtube Video - Honest Paws: Meet the Founders"
                  src="https://www.youtube.com/embed/J-h6FDvvpWk" frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-primary featured-in">
          <div className="container">
            <div className="row">
              <div className="col-10 mx-auto">
                <h2 className="text-white text-center">Featured In</h2>
                <Img fluid={this.featuredInLogosDesktop} className="d-none d-md-block mt-6" alt="Featured In Logos 1" />
                <Img fluid={this.featuredInLogosMobile} className="d-md-none mx-4 my-6" alt="Featured In Logos 2" />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white testimonials">
          <div className="container">
            <div className="row">
              <div className="text-center col-9 mx-auto mb-5">
                <h2 className="text-primary text-center mb-0">Testimonials</h2>
              </div>

              <Carousel className="carousel-testimonials container-fluid text-left d-none d-md-block" indicators={false} controls={true} interval={20000}>
                { this.testimonials.map((testimonial, index) => {
                  return (
                    <Carousel.Item className="reviews-slide" key={index}>
                      <div className="row">
                        <div className="col-md-9 mx-auto">
                          <div className="testimonial d-flex">
                            <div className="col-2 text-right marks">“</div>
                            <h3 className="testimonial-title mb-0 py-7 col-8">{ testimonial.body }</h3>
                            <div className="text-left col-2 marks d-flex align-items-end">”</div>
                          </div>
                          <div className="testimonial-author text-black text-center">{testimonial.author}</div>
                        </div>
                      </div>
                    </Carousel.Item>
                  )
                })}
              </Carousel>

              <Carousel className="carousel-testimonials-mobile container-fluid text-left d-md-none" indicators={false} controls={true}>
                { this.testimonials.map((testimonial, index) => {
                  return (
                    <Carousel.Item className="reviews-slide" key={index}>
                      <div className="row">
                        <div className="col-md-9 mx-auto">
                          <div className="testimonial">
                            <div className="marks mark-start">“</div>
                            <h3 className="testimonial-title mb-0">{ testimonial.body }</h3>
                            <div className="text-right marks mark-end">”</div>
                          </div>
                          <div className="testimonial-author text-black text-center mb-4">{testimonial.author}</div>
                        </div>
                      </div>
                    </Carousel.Item>
                  )
                })}
              </Carousel>

            </div>
          </div>
        </section>

        <section className="sign-up-form">
          <div className="container">
            <div className="row">
              <div className="text-center col-12 col-md-8 mx-auto mb-5" id="sign-up-container">
                <h2 className="text-primary text-center mb-0">Sign Up Today to carry Honest Paws and other exciting One.Pet brands!</h2>
                <div id="form-container" className="mt-5"></div>
              </div>
            </div>
          </div>
        </section>
        
      </Layout>
    )
  }
}

export default RetailersPage

export const RetailersPagePageQuery = graphql`
  query RetailersPagePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerRetailersImageDesktop : file(relativePath: { eq: "landing-pages/D-MainImage-retailers.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    bannerRetailersImageMobile : file(relativePath: { eq: "landing-pages/R-MainImage-retailers.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    HowItWorks1Desktop : file(relativePath: { eq: "landing-pages/D-HowItWorks1-retailers.png" }) {
      childImageSharp {
        fixed (width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    } 
    HowItWorks2Desktop : file(relativePath: { eq: "landing-pages/D-HowItWorks2-retailers.png" }) {
      childImageSharp {
        fixed (width: 332, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    HowItWorks3Desktop : file(relativePath: { eq: "landing-pages/D-HowItWorks3-retailers.png" }) {
      childImageSharp {
        fixed (width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    HowItWorks1Mobile : file(relativePath: { eq: "landing-pages/R-HowItWorks1-retailers.png" }) {
      childImageSharp {
        fixed (width: 160, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    HowItWorks2Mobile : file(relativePath: { eq: "landing-pages/R-Woman-retailers.png" }) {
      childImageSharp {
        fixed (width: 160, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    HowItWorks3Mobile : file(relativePath: { eq: "landing-pages/R-HowItWorks3-retailers.png" }) {
      childImageSharp {
        fixed (width: 160, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    featuredInLogosDesktop : file(relativePath: { eq: "landing-pages/featured-in-logos-desktop.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featuredInLogosDesktop : file(relativePath: { eq: "landing-pages/featured-in-logos-desktop.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featuredInLogosMobile : file(relativePath: { eq: "landing-pages/featured-in-logos-mobile.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`