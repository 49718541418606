import React from 'react'
import {get, omit} from 'lodash'
import { Link } from 'gatsby'
import { isExternalUrlOrHash, contentfulMapToLocalized } from "../../helpers.js"
import Img from 'gatsby-image'

class HeroImage extends React.Component {
  constructor(props) {
    super(props);
    this.image = this.props.image
    this.imageProps = this.props.imageProps || {}
    this.imageTitle = get(this.props, 'image.description') || get(this.props, 'image.title')
    this.containerClassName = `hero-container ${this.props.className ? this.props.className : ''}`
  }
  render() {
    return (
      <div className={this.containerClassName} { ...this.extraAttributes }>
        { this.props.children }
        {this.image ? ( <Img fluid={this.image.fluid} alt={this.imageTitle} {...this.imageProps}/> ) : '' }
      </div>
    )
  }
}

export default HeroImage
